import {
	ACTIVATION_HISTORY,
	AUTOSHIP_HISTORY,
	AUTOSHIP_SUMMARY,
	COMMISSIONS,
	ALL_SALES,
	SUMMARY_SALES,
	ALL_TAXES,
	ALL_REFUNDS,
	STATE_SALES,
	COUNTRY_SALES,
	CONTINENT_SALES,
	PRODUCT_SALES,
	SUMMARY_TAXES,
	PERIOD_SALES,
	PAYMENT_METHOD_SALES,
	CASH_PAYMENTS,
	ORDER_VERIFICATIONS,
	DOWNLOAD_ORDER_VERIFICATIONS,
	APPROVE_ORDER_VERIFICATION,
	REJECT_ORDER_VERIFICATION,
	PROCESS_PAYMENTS,
	BV,
	PENDING_BV,
	GET_DISTRIBUTORS_COMMISSIONS,
	COMPARISON_SALES,
	DOWNLOAD_TAX_REPORT,
	DOWNLOAD_SALES_REPORT,
	USER_NOTES,
	RESTORE_BV,
	PBV,
	TOP_PBV,
	NEW_ENROLLMENT,
	TOP_NEW_ENROLLMENT,
	NEW_ENROLLED_USERS,
	GET_UNILEVEL_RANKS,
	GET_RISING_STARS,
	DOWNLOAD_RISING_STARS_REPORT,
	// GET_ANALYTICS,
	// GET_REALTIME_ANALYTICS,
	GET_VIDEOS,
	ORDERS_INFORMATION,
	ORDERS_PRODUCT_INFORMATION,
	EXPORT_SALES_DETAILS_REPORT,
	GET_RANK_HISTORIES,
	DOWNLOAD_RANK_HISTORIES_REPORT,
	GET_CUSTOMER_LIFETIME,
	ALL_FRAUD_ORDERS,
	DOWNLOAD_FRAUD_ORDERS,
	TOTAL_EARNINGS,
	TOTAL_EARNINGS_DETAILS,
	DOWNLOAD_TOTAL_EARNINGS_REPORT,
	AGENCY_SALES_REPORT,
	DOWNLOAD_AGENCY_SALES_REPORT,
	GET_SETTLEMENT_EXPENSES,
	DOWNLOAD_TOP_NEW_ENROLLMENT,
	APPROVE_ORDER_SHIPPING_VERIFICATION,
	REJECT_ORDER_SHIPPING_VERIFICATION,
	REQUEST_DOCUMENT_UPDATE_VERIFICATION,
} from '@/config/endpoint';
import { apiFilters } from '@/config/axios';
import Req from './AxiosRequest';

class Report {
	constructor() {
		this.data = new Req(apiFilters);
		this.filterlessData = new Req();
		this.customDataObject = {};
		this.errors = this.data.errors;
		this.loading = this.data.loading;
		this.pagination = this.data.pagination;
	}

	clear() {
		this.data.clear();
	}

	getActivationHistory(options) {
		const { method, endpoint } = ACTIVATION_HISTORY;
		return this.data[method](endpoint, options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getCommissions(options) {
		const { method, endpoint } = COMMISSIONS;
		return this.data[method](endpoint, options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getDistributorsCommissions(userId, options) {
		const { method, endpoint } = GET_DISTRIBUTORS_COMMISSIONS;
		return this.data[method](endpoint(userId), options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getAutoshipHistory(options) {
		const { method, endpoint } = AUTOSHIP_HISTORY;
		return this.data[method](endpoint, options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getAutoshipSummary(userId) {
		const { method, endpoint } = AUTOSHIP_SUMMARY;
		return this.data[method](endpoint(userId)).then((response) => response).catch((error) => {
			this.data.loading = false;
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getAllSales(options) {
		const { method, endpoint } = ALL_SALES;
		return this.data[method](endpoint, options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getSalesSummary(options) {
		const { method, endpoint } = SUMMARY_SALES;
		return this.data[method](endpoint, options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getSalesByCountry(options) {
		const { method, endpoint } = COUNTRY_SALES;
		return this.data[method](endpoint, options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getSalesByContinent(options) {
		const { method, endpoint } = CONTINENT_SALES;
		return this.data[method](endpoint, options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getSalesByState(options) {
		const { method, endpoint } = STATE_SALES;
		return this.data[method](endpoint, options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getSalesComparison(options) {
		const { method, endpoint } = COMPARISON_SALES;
		return this.data[method](endpoint, options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getSalesByProduct(options) {
		const { method, endpoint } = PRODUCT_SALES;
		return this.data[method](endpoint, options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getSalesByPeriod(options) {
		const { method, endpoint } = PERIOD_SALES;
		return this.data[method](endpoint, options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getSalesByPaymentMethod(options) {
		const { method, endpoint } = PAYMENT_METHOD_SALES;
		return this.data[method](endpoint, options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getAllTaxes(options) {
		const { method, endpoint } = ALL_TAXES;
		return this.data[method](endpoint, options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getAllFraudOrders(options) {
		const { method, endpoint } = ALL_FRAUD_ORDERS;
		return this.data[method](endpoint, options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getAllRefunds(options) {
		const { method, endpoint } = ALL_REFUNDS;
		return this.data[method](endpoint, options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getCashPayments(options) {
		const { method, endpoint } = CASH_PAYMENTS;
		return this.data[method](endpoint, options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getOrderVerifications(type, payload) {
		const { method, endpoint } = ORDER_VERIFICATIONS;
		return this.data[method](endpoint(type), payload).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	downloadOrderVerifications() {
		const { method, endpoint } = DOWNLOAD_ORDER_VERIFICATIONS;
		return this.data.getBlobData({ method, url: endpoint }).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	approveOrderVerification(orderId, payload, type = 'billing') {
		const { method, endpoint } = type === 'billing' ? APPROVE_ORDER_VERIFICATION : APPROVE_ORDER_SHIPPING_VERIFICATION;
		return this.data[method](endpoint(orderId), payload);
	}

	rejectOrderVerification(orderId, payload, type = 'billing') {
		const { method, endpoint } = type === 'billing' ? REJECT_ORDER_VERIFICATION : REJECT_ORDER_SHIPPING_VERIFICATION;
		return this.data[method](endpoint(orderId), payload);
	}

	requestDocumentUpdate(orderId, payload) {
		const { method, endpoint } = REQUEST_DOCUMENT_UPDATE_VERIFICATION;
		return this.data[method](endpoint(orderId), payload);
	}

	getBv(userId, options) {
		const { method, endpoint } = BV;
		return this.data[method](endpoint(userId), options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getPendingBv(userId, options) {
		const { method, endpoint } = PENDING_BV;
		return this.data[method](endpoint(userId), options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	processPayment(payload) {
		const { method, endpoint } = PROCESS_PAYMENTS;
		return this.data[method](endpoint, payload).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getTaxesSummary(options) {
		const { method, endpoint } = SUMMARY_TAXES;
		return this.data[method](endpoint, options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	downloadTaxReportExcel() {
		const { method, endpoint } = DOWNLOAD_TAX_REPORT;
		return this.data.getBlobData({ method, url: endpoint }).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getUserNotes(options) {
		const { method, endpoint } = USER_NOTES;
		return this.data[method](endpoint, options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	restoreBv(id, payload) {
		const { method, endpoint } = RESTORE_BV;
		return this.data[method](endpoint(id), payload).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getPBV(userId, options) {
		const { method, endpoint } = PBV;
		return this.data[method](endpoint(userId), options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getTopPBV(userId, options) {
		const { method, endpoint } = TOP_PBV;
		return this.data[method](endpoint(userId), options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	downloadSalesReport(data) {
		const { method, endpoint } = DOWNLOAD_SALES_REPORT;
		return this.data.getBlobData({ method, url: endpoint, data }).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	exportSalesDetailsReport(data) {
		const { method, endpoint } = EXPORT_SALES_DETAILS_REPORT;
		return this.data.getBlobData({ method, url: endpoint, data }).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getNewEnrollment(userId, options) {
		const { method, endpoint } = NEW_ENROLLMENT;
		return this.data[method](endpoint(userId), options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getTopNewEnrollment(userId, options) {
		const { method, endpoint } = TOP_NEW_ENROLLMENT;
		return this.data[method](endpoint(userId), options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	downloadTopNewEnrollment(userId, options) {
		const { method, endpoint } = DOWNLOAD_TOP_NEW_ENROLLMENT;
		return this.data.getBlobData({ method, url: endpoint(userId), data: options }).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getTopNewEnrollmentUsers(userId, options) {
		const { method, endpoint } = NEW_ENROLLED_USERS;
		const queryParams = Req.setFilters(options);
		return this.filterlessData[method](endpoint(userId, queryParams), false, true).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getUnilevelRanks(userId, options) {
		const { method, endpoint } = GET_UNILEVEL_RANKS;
		return this.data[method](endpoint(userId), options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getRisingStars(options) {
		const { method, endpoint } = GET_RISING_STARS;
		return this.data[method](endpoint, options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	// getAnalytics(userId, options) {
	// 	const { method, endpoint } = GET_ANALYTICS;
	// 	const queryParams = Req.setFilters(options);
	// 	return this.data[method](endpoint(userId, queryParams), false, true).then((response) => response).catch((error) => {
	// 		this.errors.record(error);
	// 		return Promise.reject(error);
	// 	});
	// }

	// getRealtimeAnalytics(userId) {
	// 	const { method, endpoint } = GET_REALTIME_ANALYTICS;
	// 	return this.data[method](endpoint(userId)).then((response) => response).catch((error) => {
	// 		this.errors.record(error);
	// 		return Promise.reject(error);
	// 	});
	// }

	downloadRisingStars() {
		const { method, endpoint } = DOWNLOAD_RISING_STARS_REPORT;
		return this.data.getBlobData({ method, url: endpoint }).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getRankHistories(options) {
		const { method, endpoint } = GET_RANK_HISTORIES;
		return this.data[method](endpoint, options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	downloadRankHistories() {
		const { method, endpoint } = DOWNLOAD_RANK_HISTORIES_REPORT;
		return this.data.getBlobData({ method, url: endpoint }).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getSalesOrdersInformation(options) {
		const { method, endpoint } = ORDERS_INFORMATION;
		return this.data[method](endpoint, options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getSalesOrdersProductInformation(options) {
		const { method, endpoint } = ORDERS_PRODUCT_INFORMATION;
		return this.data[method](endpoint, options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getVideos(userId, options) {
		const { method, endpoint } = GET_VIDEOS;
		return this.data[method](endpoint(userId), options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getLifetimeValues(type, options) {
		const { method, endpoint } = GET_CUSTOMER_LIFETIME;
		const queryParams = Req.setFilters(options);
		return this.data[method](endpoint(type, queryParams), false, true).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	downloadOrders() {
		const { method, endpoint } = DOWNLOAD_FRAUD_ORDERS;
		return this.data.getBlobData({ method, url: endpoint }).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getTotalEarnings(options) {
		const { method, endpoint } = TOTAL_EARNINGS;
		return this.data[method](endpoint, options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getTotalEarningsDetail(options) {
		const { method, endpoint } = TOTAL_EARNINGS_DETAILS;
		return this.data[method](endpoint, options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	downloadTotalEarningsReport(data) {
		const { method, endpoint } = DOWNLOAD_TOTAL_EARNINGS_REPORT;
		return this.data.getBlobData({ method, url: endpoint, data }).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getAgencySales(data) {
		const { method, endpoint } = AGENCY_SALES_REPORT;
		return this.data[method](endpoint, data).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	downloadAgencySalesReport(data) {
		const { method, endpoint } = DOWNLOAD_AGENCY_SALES_REPORT;
		return this.data.getBlobData({ method, url: endpoint, data }).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getSettlementExpenses(options) {
		const { method, endpoint } = GET_SETTLEMENT_EXPENSES;
		return this.data[method](endpoint, options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}
}

export default Report;
